export const streets = [
    { value: "Bremweg", label: "Bremweg" },
    { value: "De Boei", label: "De Boei" },
    { value: "De Brug", label: "De Brug" },
    { value: "De Burcht", label: "De Burcht" },
    { value: "De Hovenlaan", label: "De Hovenlaan" },
    { value: "De Hovenlaan", label: "De Hovenlaan" },
    { value: "De Keerkring", label: "De Keerkring" },
    { value: "De Meerpaal", label: "De Meerpaal" },
    { value: "Fortlaan", label: "Fortlaan" },
    { value: "Gooiland", label: "Gooiland" },
    { value: "Het Kasteel", label: "Het Kasteel" },
    { value: "Het Rooster", label: "Het Rooster" },
    { value: "Het Schip", label: "Het Schip" },
    { value: "Houtsingel", label: "Houtsingel" },
    { value: "Houttuinen-Noord", label: "Houttuinen-Noord" },
    { value: "Houttuinen-Oost", label: "Houttuinen-Oost" },
    { value: "Houttuinen-Zuid", label: "Houttuinen-Zuid" },
    { value: "Houtwal", label: "Houtwal" },
    { value: "Kasteellaan", label: "Kasteellaan" },
    { value: "Lage Landenlaan", label: "Lage Landenlaan" },
    { value: "Lupineweg", label: "Lupineweg" },
    { value: "Mansardehof", label: "Mansardehof" },
    { value: "Mergelland", label: "Mergelland" },
    { value: "Montferland", label: "Montferland" },
    { value: "Myrtillushof", label: "Myrtillushof" },
    { value: "Noorderhoogte", label: "Noorderhoogte" },
    { value: "Ommeland", label: "Ommeland" },
    { value: "Ravelijn", label: "Ravelijn" },
    { value: "Salland", label: "Salland" },
    { value: "SchuylenHof", label: "SchuylenHof" },
    { value: "Vossenbeshof", label: "Vossenbeshof" },
    { value: "Westkreek", label: "Westkreek" },
    { value: "Woudhuizerallee", label: "Woudhuizerallee" },
    { value: "Woudhuizermark", label: "Woudhuizermark" },
    { value: "Zuiderhoogte", label: "Zuiderhoogte" },
    { value: "Zutphensestraat", label: "Zutphensestraat" },
];

export const genders = [
    { value: "Man", label: "Man" },
    { value: "Vrouw", label: "Vrouw" },
    { value: "Anders", label: "Anders" },
];

export const arrangements = [
    { value: "Koop", label: "Koop" },
    { value: "Huur", label: "Huur" },
];

export const types = [
    {
        value: "Eengezinswoning rij",
        label: "Eengezinswoning rij",
    },
    {
        value: "Twee onder één kap",
        label: "Twee onder één kap",
    },
    {
        value: "Vrijstaande woning",
        label: "Vrijstaande woning",
    },
    {
        value: "Appartement/flat",
        label: "Appartement/flat",
    },
    {
        value: "Ouderen/senioren woning",
        label: "Ouderen/senioren woning",
    },
];

export const owners = [
    {
        value: "Ik heb een koophuis",
        label: "Ik heb een koophuis",
    },
    {
        value: "ik huur van een particulier",
        label: "ik huur van een particulier",
    },
    {
        value: "ik huur van de woningbouwcoöperatie",
        label: "ik huur van de woningbouwcoöperatie",
    },
];

export const ages = [
    { value: "16 of jonger", label: "16 of jonger" },
    { value: "17-20 jaar", label: "17-20 jaar" },
    { value: "21-29 jaar", label: "21-29 jaar" },
    { value: "30-39 jaar", label: "30-39 jaar" },
    { value: "40-49 jaar", label: "40-49 jaar" },
    { value: "50-59 jaar", label: "50-59 jaar" },
    { value: "60-69 jaar", label: "60-69 jaar" },
    { value: "70 of ouder", label: "70 of ouder" },
];

export const samenstellingen = [
    { value: "Alleen", label: "Alleen" },
    { value: "Samen met partner", label: "Samen met partner" },
    {
        value: "Samen met partner en 1 kind",
        label: "Samen met partner en 1 kind",
    },
    {
        value: "Samen met partner en meerdere kinderen",
        label: "Samen met partner en meerdere kinderen",
    },
    { value: "Alleenstaand met 1 kind", label: "Alleenstaand met 1 kind" },
    {
        value: "Alleenstaand met meerdere kinderen",
        label: "Alleenstaand met meerdere kinderen",
    },
];
